// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"

const HoverSkewItem = () => {
    const [skewStyle, setSkewStyle] = Example({ transform: "none" })

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.currentTarget
        const bounding = target.getBoundingClientRect()
        const x = e.clientX - bounding.left
        const y = e.clientY - bounding.top
        const center = {
            x: bounding.width / 2,
            y: bounding.height / 2,
        }
        const angleX = (center.y - y) / 5
        const angleY = (x - center.x) / 5

        setSkewStyle({
            transform: `perspective(1000px) rotateX(${angleX}deg) rotateY(${angleY}deg)`,
        })
    }

    const handleMouseLeave = () => {
        setSkewStyle({ transform: "none" })
    }

    return (
        <div
            className="item"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={skewStyle}
        >
            Hover Over Me
        </div>
    )
}

export default HoverSkewItem
